import Q from 'q';
import _ from 'underscore';

export default {
    components: {},
    data() {
        return {}
    },
    methods: {},
    created() {
        // const __this = this;
    },
    mounted() {
        // const __this = this;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
